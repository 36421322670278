.loading-wrapper {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.loading-wrapper-full-height {
  align-items: center;
  display: flex;
  height: calc(100vh - var(--navbar-height));
  height: calc(100dvh - var(--navbar-height));
  justify-content: center;
}

.loading-wrapper-full-height svg {
  margin-bottom: 42px;
}

.loading-icon {
  height: 2em;
  width: 2em;
  color: var(--text-secondary-color)
}
