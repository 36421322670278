.tag-badge {
  font-size: 14px;
  /* font-weight: 500; */
  /* padding: 8px 16px 8px 16px; */
  margin: 2px 8px 8px 0;
  padding: 1px 9px;
}

.tag-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-icon {
  margin: 1px 0 0 8px;
  font-size: 14px;
}
