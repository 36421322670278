

.full-image-modal {
  position: absolute;
  background-color: var(--modal-overlay-bg-color);
  z-index: 1000000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.full-image-wrapper {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  width: auto;
  height: auto;
  max-height: 100vh;
  max-height: 100dvh;
  max-width: 100vw;
  max-width: 100dvw;
  object-fit: contain;
  flex: 0 0 auto;
}

.close-button-wrapper {
  all: unset;
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
  color: var(--bs-body-color);
  border-radius: 28px;
  border: 1px solid var(--border-default);
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--modal-overlay-bg-color);
  z-index: 1000001;
}

.close-button-svg {
  font-size: 20px;
  z-index: 1000001
}

.image-tools {  
  background-color: var(--modal-overlay-bg-color);
  border: 1px solid var(--border-default);
  color: var(--border-default);
  display: flex;
  font-size: 17px;
  padding: 1px 8px;
  border-radius: 35px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1000001;
}

.image-tool-btn {
  margin: 0 6px;
  color: var(--overlay-modal-button-color);
}
