.artist-link {
  color: var(--text-secondary-color);
  text-decoration: none;
  font-size: 14px;
  padding: 0;
  margin: 1px 12px 2px 0;
}

.artist-link-with-border {
  border: 1px solid var(--border-default);
  border-radius: 100px;
  padding: 2px 8px;
}

.profile-picture {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 6px;
}

.margin-bottom {
  margin-bottom: 4px;
}

.artist-text-wrapper {
  display: flex;
  align-items: center;
}

.artist-remove-icon {
  margin-left: 6px;
  margin: 1px 0 0 4px;
}
