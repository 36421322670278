.header-wrapper {
  margin: auto;
  max-width: 720px;
}

.header-top-wrapper {
  display: flex;
  flex-direction: row;
}

.header-top-title {
  flex: 1;
  margin-bottom: 0px;
}

.header-top-buttons {
  display: flex;
  flex: 0;
  padding-top: 10px;
  font-size: 18px;
}

.header-top-icon {
  margin-left: 12px
}

.artist-link-wrappers {
  display: flex;
  font-size: 17px;
  margin-top: 2px;
}

.artist-link {
  font-size: 17px;
}

.tab-badge {
  margin-top: 0;
}

.main-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: auto;
  max-width: 720px;
}

.main-image {
  margin: 18px 0;
  cursor: pointer;
}

.prev {
  font-size: 26px;
  display: flex;
  justify-content: flex-start;
}

.prev-svg {
  color: var(--bs-body-color);
  margin: 0 0 0 1.25rem;
}

.next {
  font-size: 26px;
  display: flex;
  justify-content: flex-end;
}

.next-svg {
  color: var(--bs-body-color);
  margin: 0 0 0 1.25rem;
}

.admin-buttons {
  display: flex;
  flex-direction: column;
}

.edit-button {
  flex: 0 0 auto;
  margin: 0 1rem 1rem 0;
  align-self: flex-start;
}

.edit-icon {
  margin-right: 0.5rem;
}

.edit-collection {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.edit-select {
  display: flex;
}

.edit-toggle-input {
  margin-left: -0.5rem !important;
}

.edit-toggle-input-label {
  margin-left: 0.5rem;
}

.navs-wrapper {
  display: flex !important;
  justify-content: space-between;
  margin: auto;
  max-width: 720px;
}

.bottom-buttons {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 0 0;
  flex-direction: column;
}

.bottom-button {
  margin: 0 auto 1.5rem auto;
}
