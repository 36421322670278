.image-versions {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .image-versions {
    justify-content: flex-start;
    margin-bottom: 4px;
  }
}

.image-version-link {
  margin: 2px 0 0 10px;
  padding: 0 !important;
  font-size: 14px;
}

@media (max-width: 767px) {
  .image-version-link {
    margin: 0 10px 0 0;
  }
}
